import React, {useEffect, useRef} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RevSlider from "../components/rev-slider"
import CustomLink from "../components/link"
import slider2_1_1 from "../../static/img/hero-4.jpg";
import slider2_1_2 from "../../static/img/hero-5.jpg";
import slider2_1_3 from "../../static/img/hero-6.jpg";
import slider_1 from '../../static/img/irstrat/index_1.jpg'
import popup from '../../static/img/popup_mps.jpg'
import img2 from "../../static/img/img2.jpg";
import solucion1 from "../../static/img/irstrat/solucion1.jpg";
import solucion2 from "../../static/img/irstrat/solucion2.jpg";
import solucion3 from "../../static/img/irstrat/solucion3.jpg";
import iredge from "../../static/img/irstrat/iredge.svg";
import sustentabilidad from "../../static/img/irstrat/sustentabilidad.svg";
import interactivo from "../../static/img/irstrat/interactivo.svg";
import financieros from "../../static/img/irstrat/financieros.svg";
import noticias_20 from "../../static/img/blog/noticias-20.jpg";
import noticias_21 from "../../static/img/blog/noticias-21.jpg";
import noticias_19 from "../../static/img/blog/noticias-19.jpg";
import noticias_18 from "../../static/img/blog/noticias-18.jpg";

const IndexPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Layout location={props.location}>
            <SEO title="Inicio"/>
            <RevSlider/>
            <section className="pt-5 mt-5">
                <div className="container">
                    <div className="row d-flex align-items-center flex-wrap">
                        {/* Heading Area*/}
                        <div className="col-12 col-lg-6 text-center text-lg-left mb-5 mb-lg-0 wow fadeInLeft"
                             data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="">
                                {/*<h6 className="sub-title main-color">Basic Info About Shop</h6>*/}
                                <h2 className="section_title_two text-dark mb-0">Desarrollamos la estrategia y
                                    aterrizamos la táctica</h2> <br/>
                                <p className="paragraph">Creemos que el precio de los instrumentos financieros listados
                                    en los mercados es producto de sus fundamentales operacionales y su percepción; por
                                    lo que estructuramos y ejecutamos sólidos planes de R.I., enfocados a obtener una
                                    óptima valuación.</p>
                                {/*List*/}

                                <CustomLink to="/servicios"
                                            className="get_btn btn_hover hover_color">Servicios</CustomLink>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="text-center text-lg-right wow fadeInRight" data-wow-delay="0.1s"
                                 data-wow-duration="1.0s">
                                <img className="img-fluid" src={img2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_consulting_area section_gap">
                <div className="container">
                    <div className="section_title black">
                        <h2 className="section_title_two text-dark">3 Pilares en la estrategia de R.I.</h2>
                    </div>
                    <div className="row about_info index">

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1.0s">
                            <a href="es/servicios/irstrat_2">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} src={solucion2}/>
                                    </div>
                                    <h3 className="sec_title">Mensaje Estratégico </h3>
                                    <p>
                                        El “mensaje manda”. Definimos y ejecutamos la estrategia de su mensaje a los
                                        mercados, a través de la estructuración y correcta difusión de una atractiva
                                        tesis de inversión que pueda impulsar la valoración de sus instrumentos
                                        financieros.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInRight" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <a href="es/servicios/irstrat_3">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} src={solucion3}/>
                                    </div>
                                    <h3 className="sec_title">Lobbying e Inteligencia</h3>
                                    <p>
                                        “No se puede llegar a un puerto sin una brújula”. Ayudamos a nuestros clientes a
                                        conocer el verdadero sentir del mercado y a expandir su base de inversionistas;
                                        valiéndonos de nuestra especializada red de contactos con las principales
                                        institucionales financieras nacionales e internacionales.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInLeft" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <a href="es/servicios/irstrat_1">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} src={solucion1}/>
                                    </div>

                                    <h3 className="sec_title">Imagen e Identidad</h3>
                                    <p>
                                        “También se juzga a un libro por su portada”, por lo que maximizamos la calidad
                                        de los materiales dirigidos a sus inversionistas (sitio, presentaciones y
                                        reportes financieros), y proveemos robustas herramientas tecnológicas para la
                                        distribución del mensaje.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section className="offer_area section_gap">
                <div className="container">
                    <div className="section_title">
                        <h3>Materiales de imagen, identidad y cumplimiento</h3>
                    </div>
                    <div className="service_slider service_slider_one owl-carousel">
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={iredge}/>
                                    <h3>Sitio de RI</h3>
                                    <p>
                                        Desarrollo total: contenido en español e inglés, identidad gráfica y plataforma
                                        responsiva
                                        inteligente.
                                    </p>
                                </div>
                            </div>

                        </div>


                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={sustentabilidad}/>
                                    <h3>Informe anual</h3>
                                    <p>
                                        Desarrollo integral: edición gráfica, recopilación de
                                        información y edición de contenido.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={interactivo}/>
                                    <h3>Informe interactivo</h3>
                                    <p>
                                        Edición de contenido en español e inglés, identidad gráfica y plataforma
                                        responsiva inteligente.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={sustentabilidad}/>
                                    <h3>Informe de sustentabilidad</h3>
                                    <p>
                                        Informe de acuerdo a los más
                                        recientes estándares
                                        GRI, a la par de lograr la máxima calidad en contenido en español e inglés y
                                        elementos visuales.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={financieros}/>
                                    <h3>Feed financiero</h3>
                                    <p>
                                        Desarrollo de ticker de información de precios, así como gráficas y descargas
                                        interactivas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="latest_blog_area">
                <div className="container">
                    <div className="section_title">
                        <h2>Nuestras publicaciones recientes</h2>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="lt_blog_item">
                                <CustomLink to="/blog/fondos-cobertura" className="img_hover">
                                    <img alt={" "} src={noticias_21}/>
                                </CustomLink>
                                <div className="lt_content">
                                    <CustomLink to="/blog/fondos-cobertura">
                                        <h3>Fondos de cobertura</h3>
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="lt_blog_item">
                                <CustomLink to="/blog/dos-practicas" className="img_hover">
                                    <img alt={" "} src={noticias_20}/>
                                </CustomLink>
                                <div className="lt_content">
                                    <CustomLink to="/blog/dos-practicas">
                                        <h3>Dos prácticas de relación con inversionistas que tomaron mayor relevancia
                                            debido al COVID-19</h3>
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="lt_blog_item">
                                <CustomLink to="/blog/perspectivas-2021" className="img_hover">
                                    <img alt={" "} src={noticias_19}/>
                                </CustomLink>
                                <div className="lt_content">
                                    <CustomLink to="/blog/perspectivas-2021">
                                        <h3>Un vistazo a las perspectivas de 2021</h3>
                                    </CustomLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="video_area">
                <div className="container">
                    <div className="row video_content">
                        <div className="col-md-6">
                            <h5 className="b_title">Trabajemos juntos maximizando la valuación de sus intrumentos
                                financieros</h5>
                            <h2>
                                Somos líderes en el <br/>
                                mercado
                            </h2>
                            <a
                                className="video-pop pl-0"
                                href="https://player.vimeo.com/video/358367655?autoplay=1&title=0&byline=0&portrait=0"
                            >
                                Vea nuestro video
                                <br/> corporativo
                            </a>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <a
                                className="video-pop mb-5 mt-5 mt-md-0"
                                href="https://player.vimeo.com/video/358367655?autoplay=1&title=0&byline=0&portrait=0"
                            >
                                <i className="flaticon-circular"/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <div id="modal-index" className="modal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body">
                            <a href="https://investorcloud.s3.amazonaws.com/irstrat/documents/wp/MPS-2020.pdf"
                               target="_blank"><img src={popup} className="img-fluid" alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default IndexPage
